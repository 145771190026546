import { throttle } from 'lodash-es'

import { storefrontInstance } from '@scripts/utils/api'
import { composeImageUrl } from '@scripts/utils/helpers'
import { metaobjectsQuery } from '@scripts/utils/queries'

const GalleryGrid = ({
  itemsPerPage
}) => ({
  hasNextPage: true,
  endCursor: '',
  isLoading: true,
  isFetchingNext: false,

  galleryItems: [],

  init () {
    this.fetchGallery({
      isFetchingNext: false
    })
  },

  handleScroll: throttle(function () {
    if (!this.hasNextPage || this.isFetchingNext || this.isLoading) return

    const container = this.$refs.galleryGrid
    const containerBottom = container.getBoundingClientRect().bottom

    const windowBottom = window.innerHeight

    if (containerBottom <= windowBottom) {
      this.fetchGallery({ isFetchingNext: true })
    }
  }, 300),

  async fetchGallery ({ isFetchingNext }:
    {
      isFetchingNext: boolean,
    }) {
    if (isFetchingNext) {
      this.isFetchingNext = true
    }
    try {
      const res = await storefrontInstance.post('graphql.json', metaobjectsQuery({
        type: 'gallery_order',
        first: itemsPerPage,
        after: this.endCursor
      }))

      if (res.status !== 200) return

      const data = res.data.data.metaobjects.edges[0].node.fields[0].references

      this.hasNextPage = data.pageInfo.hasNextPage
      this.endCursor = data.pageInfo.endCursor

      this.galleryItems = [...this.galleryItems, ...data.edges.map(edge => this.transformMetaobjectData(edge))]
    } catch (error) {
      throw new Error(error.message)
    } finally {
      this.isLoading = false

      if (isFetchingNext) {
        this.isFetchingNext = false
      }
    }
  },

  transformMetaobjectData (data) {
    if (!data) return

    const { node } = data

    return {
      id: node.id.replace('gid://shopify/Metaobject/'),
      fields: node.fields.reduce((acc, item) => {
        if (item.key === 'image') {
          const { originalSrc, width, height } = item.reference.image
          const { src, srcSets } = composeImageUrl({ src: originalSrc, width })

          acc[item.key] = {
            ...item.reference.image,
            aspectRatio: `${(height / width) * 100}%`,
            src,
            srcSets
          }
        } else {
          acc[item.key] = item.value
        }
        return acc
      }, {})
    }
  }
})

export default GalleryGrid
