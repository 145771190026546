const MainContactPage = () => ({
  topic: '',
  isTopicOpen: false,

  handleTopicClick (topic: string) {
    this.topic = topic
    this.isTopicOpen = false
  },

  handleTopicToggle (): void {
    this.isTopicOpen = !this.isTopicOpen
  }
})

export default MainContactPage
