import Swiper from 'swiper'
import { Autoplay, Navigation } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/navigation'

const BrandSlider = () => ({
  swiper: null,
  init () {
    this.intiSwiper()
  },
  intiSwiper () {
    this.Swiper = new Swiper(this.$refs.gallery, {
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      modules: [Navigation, Autoplay],
      speed: 6000,
      slidesPerView: 2,
      breakpoints: {
        640: {
          slidesPerView: 3
        },
        768: {
          slidesPerView: 4
        },
        991: {
          slidesPerView: 5
        }
      }
    })
  }
})

export default BrandSlider
