import Swiper from 'swiper'
import { Scrollbar } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/scrollbar'

const SwiperCarousel = ({
  showScrollbarDesktop = false,
  showScrollbarMobile = true,
  slidesPerViewDesktop = 3,
  slidesPerViewMobile = 1.5,
  slidesPerViewTablet = 2.5,
  spaceBetweenDesktop = 30,
  spaceBetweenMobile = 8,
  slidesOffsetAfterDesktop = 0,
  slidesOffsetBeforeDesktop = 0,
  slidesOffsetAfterTablet = 0,
  slidesOffsetBeforeTablet = 0,
  slidesOffsetAfterMobile = 0,
  slidesOffsetBeforeMobile = 0
}) => ({
  init () {
    this.initSwiper()
  },

  swiper: null,

  initSwiper () {
    this.swiper = new Swiper(this.$refs.swiper, {
      breakpoints: {
        1024: {
          scrollbar: {
            enabled: showScrollbarDesktop
          },
          spaceBetween: spaceBetweenDesktop,
          slidesOffsetAfter: slidesOffsetAfterDesktop,
          slidesOffsetBefore: slidesOffsetBeforeDesktop,
          slidesPerView: slidesPerViewDesktop
        },
        768: {
          slidesOffsetAfter: slidesOffsetAfterTablet,
          slidesOffsetBefore: slidesOffsetBeforeTablet,
          slidesPerView: slidesPerViewTablet
        }
      },
      scrollbar: {
        draggable: true,
        enabled: showScrollbarMobile,
        el: '.swiper-scrollbar'
      },
      modules: [Scrollbar],
      slidesOffsetAfter: slidesOffsetAfterMobile,
      slidesOffsetBefore: slidesOffsetBeforeMobile,
      spaceBetween: spaceBetweenMobile,
      slidesPerView: slidesPerViewMobile,
      resistanceRatio: 0
    })
  }
})

export default SwiperCarousel
