import { throttle } from 'lodash-es'

const HeaderMain = ({
  isScaled
}) => ({
  isMobileMenuOpen: false,

  init () {
    if (isScaled) {
      this.initScrollEvents()
      this.handleScroll()
      this.hoverAnimation()
    }
  },

  initScrollEvents (): void {
    window.addEventListener(
      'scroll',
      throttle(this.handleScroll.bind(this), 250)
    )
  },

  handleScroll (): void {
    const offset: number = window.matchMedia('(min-width: 1024px').matches ? 300 : 20

    const isPast30px: boolean = window.scrollY > offset

    const isAtTop: boolean = window.scrollY === 0

    if (isPast30px) {
      this.$el.classList.remove('header--scaled')
    }

    if (isAtTop) {
      this.$el.classList.add('header--scaled')
    }
  },

  toggleMobileMenu (): void {
    this.isMobileMenuOpen = !this.isMobileMenuOpen
  },

  hoverAnimation (): void {
    const menu = this.$refs.headerMenu
    const lineThrough = this.$refs.lineThrough
    const menuItems = menu.querySelectorAll('li')

    const handleMouseEnter = (item: HTMLElement) => {
      // Show linethough upon hover
      lineThrough.style.opacity = 1

      // Animate the position and width of linethrough upon menu item hover
      const menuXPosition = menu.getBoundingClientRect().x
      const itemXPosition = item.getBoundingClientRect().x - menuXPosition
      lineThrough.style.left = `${itemXPosition + 40}px`
      lineThrough.style.width = `${item.offsetWidth}px`
    }

    const handleMouseLeave = () => {
      lineThrough.style.opacity = '0'
    }

    menuItems.forEach(item => {
      item.addEventListener('mouseenter', () => handleMouseEnter(item))
      item.addEventListener('mouseleave', handleMouseLeave)
    })
  }
})

export default HeaderMain
