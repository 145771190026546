const ScrollToSection = () => ({
  handleArrowClick (e: PointerEvent) {
    const target = e.target as HTMLButtonElement

    const parentElement = target.parentElement
    const parentSection = parentElement.closest('section')
    const nextElementSibling = parentSection.nextElementSibling

    if (nextElementSibling) {
      nextElementSibling.scrollIntoView({ behavior: 'smooth' })
    }
  }
})

export default ScrollToSection
