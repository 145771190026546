import axios from 'axios'
import {
  STOREFRONT_URL,
  STOREFRONT_API_ACCESS_TOKEN_DEV,
  STOREFRONT_API_ACCESS_TOKEN_PROD
} from './config'

const getStoreFrontToken = () => {
  switch (window.Shopify.shop) {
    case 'white-canvas-co-dev.myshopify.com':
      return STOREFRONT_API_ACCESS_TOKEN_DEV
    default:
      return STOREFRONT_API_ACCESS_TOKEN_PROD
  }
}

export const storefrontInstance = axios.create({
  baseURL: STOREFRONT_URL,
  headers: {
    'Content-Type': 'application/graphql',
    'X-Shopify-Storefront-Access-Token': getStoreFrontToken()
  }
})
