import Swiper from 'swiper'
import { Autoplay, EffectFade } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-fade'

const ExpandableMedia = () => ({
  init () {
    this.intiSwiper()
  },
  intiSwiper () {
    this.Swiper = new Swiper(this.$refs.images, {
      loop: true,
      speed: 800,
      slidesPerView: 1,
      spaceBetween: 1,
      fadeEffect: { crossFade: true },
      modules: [Autoplay, EffectFade],
      effect: 'fade',
      allowTouchMove: true
    })
  }
})

export default ExpandableMedia
