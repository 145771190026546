import Swiper from 'swiper'
import { Autoplay, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'

const MediaColumns = () => ({
  swiper: null,

  init () {
    this.intiSwiper()
  },

  intiSwiper () {
    this.Swiper = new Swiper(this.$refs.gallery, {
      loop: true,
      slidesPerView: 1,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      modules: [Navigation, Autoplay],
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        640: {
          slidesPerView: 2
        },
        768: {
          slidesPerView: 3
        }
      }
    })
  }
})

export default MediaColumns
