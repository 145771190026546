import 'vite/modulepreload-polyfill'
import Alpine from 'alpinejs'

import SwiperCarousel from '@scripts/components/swiper-carousel'
import Video from '@scripts/components/video'
import ScrollToSection from '@scripts/components/scroll-to-section'

import HeaderMain from '@scripts/sections/header'
import MarqueeGallery from '@scripts/sections/marquee-gallery'
import MediaColumns from '@scripts/sections/media-columns'
import testimonialSlider from '@scripts/sections/testimonial-slider'
import MainContactPage from '@scripts/sections/main-contact-page'
import GalleryGrid from '@scripts/sections/gallery-grid'
import BrandSlider from '@scripts/sections/brand-slider'
import ExpandableMedia from '@scripts/sections/expandable-media'

window.Alpine = Alpine

document.addEventListener('alpine:init', () => {
  // store

  // components
  Alpine.data('swiperCarousel', SwiperCarousel)
  Alpine.data('video', Video)
  Alpine.data('scrollToSection', ScrollToSection)

  // sections
  Alpine.data('headerMain', HeaderMain)
  Alpine.data('marqueeGallery', MarqueeGallery)
  Alpine.data('mediaColumns', MediaColumns)
  Alpine.data('testimonialSlider', testimonialSlider)
  Alpine.data('mainContactPage', MainContactPage)
  Alpine.data('galleryGrid', GalleryGrid)
  Alpine.data('brandSlider', BrandSlider)
  Alpine.data('expandableMedia', ExpandableMedia)
})

Alpine.start()
