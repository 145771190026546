import { Autoplay, EffectFade, Navigation, Pagination, Thumbs } from 'swiper/modules'
import Swiper from 'swiper'

import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/autoplay'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const testimonialSlider = () => ({
  swiper: null,

  init () {
    this.intiSwiper()
  },

  intiSwiper () {
    this.Swiper = new Swiper(this.$refs.gallery, {
      loop: true,
      speed: 800,
      slidesPerView: 1,
      spaceBetween: 2,
      fadeEffect: { crossFade: true },
      modules: [Autoplay, EffectFade, Navigation, Thumbs, Pagination],
      effect: 'fade',
      autoplay: {
        delay: 6000,
        disableOnInteraction: true
      },

      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },

      // pagination
      pagination: {
        el: '.swiper-pagination'
      },

      // And if we need scrollbar
      scrollbar: {
        el: '.swiper-scrollbar'
      },

      thumbs: {
        swiper: this.$refs.images
      }
    })

    this.Swiper = new Swiper(this.$refs.images, {
      loop: true,
      speed: 800,
      slidesPerView: 1,
      spaceBetween: 1,
      fadeEffect: { crossFade: true },
      modules: [Autoplay, EffectFade],
      effect: 'fade',
      allowTouchMove: false
    })
  }
})

export default testimonialSlider
