export const metaobjectsQuery = (
  { type, first, after }:
  { type: string, first?: number, after?: string }
) => {
  return `
  query metaobject {
    metaobjects(
      type: "${type}",
      sortKey: "updated_at",
      first: 1
    ) {
      edges {
        cursor
        node {
          handle
          id
          type
          fields {
            key
            type
            value
            references(
              ${first ? `,first: ${first}` : ''}
              ${after ? `,after: "${after}"` : ''}
            ) {
              edges {
                node {
                  ... on Metaobject {
                    id
                    fields {
                      reference {
                        ... on MediaImage {
                          alt
                          image {
                            altText
                            height
                            id
                            originalSrc
                            width
                          }
                        }
                      }
                      key
                      type
                      value
                    }
                  }
                }
                cursor
              }
              pageInfo {
                endCursor
                hasNextPage
              }
            }
          }
        }
      }
    }
  }
  `
}
